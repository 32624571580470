<template>
  <div>
    <v-card-text>
      <v-container>
        <v-btn color="secondary" @click="addComponent()">
          Add QR
        </v-btn>
        <!-- <v-col cols="12">
          <v-text-field
            v-model="qrInput"
            label="Input Text"
            outlined
            clearable
          />

          <qr-code size="100" :text="qrInput" v-if="qrInput"></qr-code>
        </v-col> -->
        <v-col md="12"> 
          <v-row >
            <v-col md="3" v-for="index in count" :key="index">
              <QrCodeComponent :textInput="qrInput" />
            </v-col>
          </v-row>
        </v-col>
      </v-container>
      <!-- <qrcode-vue :value="value"></qrcode-vue> -->
    </v-card-text>
  </div>
</template>
<script>
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);
// import QrcodeVue from "qrcode.vue";
// Vue.component("qrcode-vue", QrcodeVue);

import QrCodeComponent from "../components/QrCode.vue";

export default {
  data() {
    return {
      count: 1,
      qrInputs: {},
      value: "https://example.com",
      size: 300,
      qrInput: ""
    };
  },
  components: { QrCodeComponent },
  methods: {
    addComponent() {
      this.count += 1;
      console.log(this.count);
    }
  }
};
</script>
